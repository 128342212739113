import translations from '../translation.js'
import I18n from 'i18n-js'
import * as Papa from 'papaparse'

// Translations setup
let defLocale = 'hu'
const langStorageKey = 'language'
const loc = getLocale()
const csv = Papa.parse(translations)
const languages = csv.data[1]

if (!loc) {
  setLocale(defLocale)
} else {
  defLocale = loc
}

I18n.defaultLocale = defLocale
I18n.locale = defLocale
I18n.currentLocale()

// Translation file process

function createKey (x) {
  if (x) {
    return x.toLowerCase()
  } else {
    return x
  }
}

I18n.translations = csv.data.reduce((res, data, i) => {
  if (i === 0) {
    // skip first empty line
  } else if (i === 1) {
    // language code headers
    data.forEach((x) => {
      res[x] = {}
    })
  } else if (data.length > 1) {
    languages.forEach((x, i) => {
      res[x][createKey(data[0])] = data[i] || data[0]
    })
  }
  return res
}, {})

function _ (key) {
  return I18n.t(createKey(key))
}

function getLocale (val) {
  const locale = localStorage.getItem(langStorageKey)
  if (val) {
    if (val[locale]) {
      return val[locale]
    }
    const firstFilledItem = languages.find((x) => val[x])
    if (firstFilledItem) {
      return val[firstFilledItem]
    }
    return ' - '
  }
  return locale
}

function getObjTrans (v, id) {
  try {
    let d = JSON.parse(v[id])
    return d[getLocale()]
  } catch (e) {
    console.warn(`"${v[id]}" (${id}) is not JSON, no localisation avaible`)
    return v[id]
  }
}

function setLocale (loc) {
  if (languages.indexOf(loc) !== -1) {
    localStorage.setItem(langStorageKey, loc)
  }
}

function getAllLocales () {
  return languages
}

function __ (x) {
  if (x && x instanceof Object) {
    const locale = x[getLocale()]
    if (!locale) {
      return x.en
    }
    return locale
  }
  return ''
}

export {
  _,
  __,
  getLocale,
  setLocale,
  getObjTrans,
  getAllLocales
}

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import sdk from 'locbee-javascript-sdk'
import backendEnv from './backend.env'
import { LoginNavigationContainer } from './auth/containers'
import { MainNavigationContainer } from './navigation/containers'
import { AuthProvider, withAuth } from './auth/context/authContext'
import { groupService } from './groups/services'
import './index.scss'

global.React = React

Object.entries(backendEnv).forEach(([k, v]) => {
  sdk.env[k] = v
})

class App extends Component {
  state = {
    initialized: false
  }

  async componentDidMount () {
    await groupService.initialize()

    if (groupService.groups) {
      this.setState({ initialized: true })
    }
  }

  render () {
    const { auth } = this.props

    if (!auth.loggedIn) {
      return <LoginNavigationContainer />
    }

    if (this.state.initialized) {
      return <MainNavigationContainer />
    } else {
      return null
    }
  }
}

const AppWithAuth = withAuth(App)

ReactDOM.render(
  <AuthProvider>
    <AppWithAuth />
  </AuthProvider>,
  document.getElementById('root')
)

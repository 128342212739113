import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { Button } from 'reactstrap'

class Badge extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired
  }

  render () {
    const { label } = this.props

    return <Button className='badge badge-danger'>{label}</Button>
  }
}

export default Badge

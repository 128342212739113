import { _ } from './lib/locale'

export default {
  items: [
    {
      name: _('Groups'),
      url: '/groups',
      icon: 'fa fa-users'
    },
    {
      name: _('Users'),
      url: '/users',
      icon: 'fa fa-user'
    },
    {
      name: _('Zones'),
      url: '/zones',
      icon: 'fa fa-map-marker'
    },
    {
      name: _('Beacons'),
      url: '/beacons',
      icon: 'fa fa-bluetooth-b'
    },
    {
      name: _('Opening hours'),
      url: '/openinghours',
      icon: 'fa fa-clock-o'
    },
    {
      name: _('Contents'),
      url: '/contents',
      icon: 'fa fa-bars'
    },
    {
      name: _('News'),
      url: '/news',
      icon: 'fa fa-newspaper-o'
    },
    {
      name: _('Events'),
      url: '/events',
      icon: 'fa fa-calendar'
    },
    {
      name: _('Tours'),
      url: '/tours',
      icon: 'fa fa-location-arrow'
    },
    {
      name: _('Quiz'),
      url: '/quiz',
      icon: 'fa fa-question'
    },
    {
      name: _('Treasure-Hunt'),
      url: '/treasure-hunt',
      icon: 'fa fa-map'
    },
    {
      name: _('Coupons'),
      url: '/coupons',
      icon: 'fa fa-ticket'
    },
    {
      name: _('Rating'),
      url: '/ratings',
      icon: 'fa fa-star'
    },
    {
      name: _('Path'),
      url: '/paths',
      icon: 'fa fa-road'
    },
    {
      name: _('Translations'),
      url: '/translations',
      icon: 'fa fa-language'
    },
    {
      name: _('Statistics'),
      url: '/stats',
      icon: 'fa fa-bar-chart'
    },
    {
      name: _('Useful informations'),
      url: '/informations',
      icon: 'fa fa-info-circle'
    },
    {
      name: _('Pages'),
      url: '/pages',
      icon: 'fa fa-desktop'
    },
    {
      name: _('Attachment'),
      url: '/attachment',
      icon: 'fa fa-paperclip'
    }
  ]
}

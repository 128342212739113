import React from 'react'

const forwardStatic = ['path', 'navigationOptions']

function createContextHOC (Context, propName) {
  return function withContext (Component) {
    function ComponentWithContext (props) {
      return (
        <Context.Consumer>
          {(context) =>
            <Component
              {...props}
              {...{ [propName]: context }}
            />
          }
        </Context.Consumer>
      )
    }
    forwardStatic.forEach((x) => {
      if (Component[x]) {
        ComponentWithContext[x] = Component[x]
      }
    })
    return ComponentWithContext
  }
}

export default {
  createContextHOC
}

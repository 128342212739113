import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import autobind from 'autobind-decorator'
import styles from './LoginLayout.module.scss'

class LoginLayout extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func
  }

  state = {
    email: '',
    password: ''
  }

  @autobind
  handleSubmit (e) {
    e.preventDefault()

    const { onSubmit } = this.props

    if (onSubmit) {
      onSubmit(this.state)
    }
  }

  render () {
    const { email, password } = this.state

    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <CardGroup>
                <Card className='p-4'>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1 className={styles.title}>Login</h1>
                      <p className='text-muted'>Sign In to your account</p>
                      <InputGroup className='mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fa fa-at' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                          type='email'
                          placeholder='E-mail'
                          autoComplete='email' />
                      </InputGroup>
                      <InputGroup className='mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fa fa-key' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={password}
                          onChange={(e) => this.setState({ password: e.target.value })}
                          type='password'
                          placeholder='Password'
                          autoComplete='current-password' />
                      </InputGroup>
                      <Row>
                        <Col xs='6'>
                          <Button color='primary' type='submit' className='px-4'>Login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default LoginLayout

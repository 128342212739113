import { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LoginScreen } from '../screens'

class LoginNavigationContainer extends Component {
  render () {
    return (
      <Router>
        <Switch>
          <Route path='/' component={LoginScreen} />
        </Switch>
      </Router>
    )
  }
}

export default LoginNavigationContainer

import { PureComponent } from 'react'
import { Button, Row } from 'reactstrap'
import styles from './ErrorIndicator.module.scss'

class ErrorIndicator extends PureComponent {
  render () {
    const { error, onRetry } = this.props

    console.log('ERROR', error)

    return (
      <div className={styles.container}>
        <div className={styles.box}>
          <Row>
            <div className={styles.art}>
              <img src={require('./sad-bee.png')} alt='' />
            </div>
            <div className={styles.col}>
              <h1 className='display-3'>Oh, no!</h1>
              <h2>Something went wrong.</h2>
              <p className='lead'>An unexcepted error happened right now.</p>
              <hr className='my-2' />
              <p>Error: {error.message}</p>
              {onRetry
                ? <p className='lead'>
                  <Button color='primary' onClick={onRetry}>Retry</Button>
                </p>
                : null}
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

export default ErrorIndicator

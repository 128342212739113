import sdk from 'locbee-javascript-sdk'

const groups = {}

async function initialize () {
  const documents = await sdk.documents.getAllDocumentFromList('/groups')
  documents.forEach((x) => {
    groups[x.id] = {
      id: x.id,
      data: {
        id: x.id,
        ...x.data
      }
    }
  })
}

function getGroupByRole (role) {
  return Object.values(groups).find((x) => x.data.role === role)
}

export default {
  initialize,
  getGroupByRole,
  groups
}

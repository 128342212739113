import React, { Component } from 'react'
import { Nav, Button } from 'reactstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { withAuth } from '../../../auth/context/authContext'
import autobind from 'autobind-decorator'
import { _, getAllLocales, getLocale, setLocale } from '../../../lib/locale'

import { AppSidebarToggler, AppNavbarBrand } from '@coreui/react'
import HeaderDropdown from '../HeaderDropdown'

class Header extends Component {
  static propTypes = {
    langs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        lang: PropTypes.string.isRequired
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string
    })
  }

  @autobind
  logoutClick () {
    const { auth } = this.props
    auth.logout()
  }

  changeLang (newVal) {
    setLocale(newVal.value)
    if (window.confirm(_('Reload to apply changes?'))) {
      window.location.reload()
    }
  }

  render () {
    const { user, langs } = this.props

    return (
      <React.Fragment>
        <AppSidebarToggler className='d-lg-none' display='md' mobile />
        <AppNavbarBrand
          full={{ src: require('../../../common/assets/logo.png'), width: 90, height: 32, alt: 'Locbee logo' }}
          minimized={{ src: require('../../../common/assets/logo-small.png'), width: 30, height: 30, alt: 'Locbee logo' }}
        />
        <AppSidebarToggler className='d-md-down-none' display='lg' />
        <Nav className='ml-auto' navbar>
          <HeaderDropdown langs={langs} />
          <HeaderDropdown onLogout={this.props.onLogout} user={user} />
        </Nav>

        <div
          style={{
            width: '80px'
          }}
        >
          <Select
            menuPlacement='auto'
            value={{ label: getLocale(), value: getLocale() }}
            options={getAllLocales().map((x) => { return { label: x, value: x } })}
            onChange={this.changeLang}
            isSearchable={false}
          />
        </div>

        <Button
          style={{ margin: 5 }}
          onClick={this.logoutClick}
        >
          {_('Logout')}
        </Button>
      </React.Fragment>
    )
  }
}

export default withAuth(Header)

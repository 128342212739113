import { Component, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LoadingIndicator } from '../../common/components'
import { getRoutesByRole } from '../../routes'
import { MainLayout } from '../components'
import { withAuth } from '../../auth/context/authContext'

const langs = null /* [
  { code: 'hu', lang: 'Magyar' },
  { code: 'us', lang: 'English' }
] */

const user = null /* {
  id: 5,
  name: 'Teszt Elek',
  avatarUrl: null
} */

class MainNavigationContainer extends Component {
  render () {
    const { auth } = this.props

    return (
      <Router>
        <MainLayout langs={langs} user={user}>
          <Switch>
            <Suspense fallback={<LoadingIndicator />}>
              {getRoutesByRole(auth.role).map((route, idx) => (
                route.component
                  ? <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} />
                    )} />
                  : null
              ))}
            </Suspense>
          </Switch>
        </MainLayout>
      </Router>
    )
  }
}

export default withAuth(MainNavigationContainer)


export default {
	staticResourcesBucketUrl: 'https://s3-eu-central-1.amazonaws.com/giga2003-staticresourcess3bucket-e316y5k5y8jr',
	documentStoreBucketUrl: 'https://s3-eu-central-1.amazonaws.com/giga2003-documentstores3bucket-75c4f3xueanb',
	apiGatewayBaseUrl: 'https://e1es2qlorj.execute-api.eu-central-1.amazonaws.com/Prod',
	realtimeTrackingWebSocketUri: 'wss://9fc42c79ag.execute-api.eu-central-1.amazonaws.com/Prod',
	languageCodes: 'hu,en'.split(','),
	onesignalAuthToken: 'Basic OWM2NjMyZjYtNzgwYy00YTllLWFhMTktODc0Y2UwZmUyOTNj',
	onesignalAppId: '3a947e10-3e1b-4fe2-83e7-db9a8ea47c1d',
	statTypes: '{"data":["qrcode","heatmap","real-time","active-users-monthly","active-users-daily","total-users","total-zone-visits-monthly","total-zone-visits","dwell-time-by-dow","asked-per-answered-quiz","right-per-wrong-quiz","rating-value","push-sent","push-opened","treasure_hunt-gamers","asked-per-answered-treasure_hunt"]}'
}


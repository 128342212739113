import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import autobind from 'autobind-decorator'

class HeaderDropdown extends Component {
  static propTypes = {
    langs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        lang: PropTypes.string.isRequired
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string
    })
  }

  static defaultProps = {
    account: false,
    language: false
  }

  state = {
    dropdownOpen: false,
    language: <DropdownItem><i className='flag-icon flag-icon-us' />English</DropdownItem>
  }

  @autobind
  toggle () {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  renderFlagItem (flagCode, language) {
    const flagClass = 'flag-icon flag-icon-' + flagCode

    return (
      <DropdownItem><i className={flagClass} />{language}</DropdownItem>
    )
  }

  dropLanguage () {
    const { langs } = this.props

    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          {this.state.language}
        </DropdownToggle>
        <DropdownMenu right>
          {langs.map((x, i) => {
            const item = this.renderFlagItem(x.code, x.lang)
            return <div key={i} onClick={() => this.setState({ language: item })}>{item}</div>
          })}
        </DropdownMenu>
      </Dropdown>
    )
  }

  dropAccount () {
    const { user } = this.props

    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <img src={user.avatarUrl} className='img-avatar' alt='IMG' />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag='div' className='text-center'><strong>{user.name}</strong></DropdownItem>
          <DropdownItem header tag='div' className='text-center'><strong>Settings</strong></DropdownItem>
          <DropdownItem><i className='fa fa-user' /> Profile</DropdownItem>
          <DropdownItem><i className='fa fa-wrench' /> Settings</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={this.props.onLogout}><i className='fa fa-lock' /> Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  render () {
    const { user, langs } = this.props
    return (
      langs
        ? this.dropLanguage()
        : user
          ? this.dropAccount()
          : null
    )
  }
}

export default HeaderDropdown
